<template>
  <BCard>
    <div class="text-black text-2xl font-semibold mb-2">
      Training Program
    </div>
    <BCol class="p-0 d-flex">
      <BDropdown
        id="dropdown-1"
        class="filter h-100"
        no-caret
        variant="primary"
        toggle-class="text-decoration-none toggle-custom button-primary rounded-lg py-0 px-1"
      >
        <h5>Filter</h5>
        <template #button-content>
          <div class="py-[7px]">
            <b-img
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/filter-search.svg"
              class="h-[20px]"
            />
          </div>
        </template>
        <BDropdown
          id="dropdown-2"
          class="w-100"
          toggle-class="text-left"
          variant="none"
          left
          no-caret
          dropright
        >
          <template #button-content>
            <span class="hover-salmon text-6 w-100">Level</span>
          </template>
          <BDropdownForm style="width: 200px">
            <BFormCheckbox
              v-for="(status, index) in levelOptions"
              :key="index"
              v-model="levelSelected"
              :name="`checkbox-${index}`"
              class="text-6 mb-1"
              :value="status.value"
              @change="getListData()"
            >
              {{ status.text }}
            </BFormCheckbox>
          </BDropdownForm>
        </BDropdown>
        <BDropdown
          id="dropdown-3"
          class="w-100"
          toggle-class="text-left"
          variant="none"
          left
          no-caret
          dropright
        >
          <template #button-content>
            <span class="hover-salmon text-6 w-100">Jenis Event</span>
          </template>
          <BDropdownForm style="width: 200px">
            <BFormCheckbox
              v-for="(status, index) in eventTypeOptions"
              :key="index"
              v-model="eventTypeSelected"
              :name="`checkbox-${index}`"
              class="text-6 mb-1"
              :value="status.value"
              @change="getListData()"
            >
              {{ status.text }}
            </BFormCheckbox>
          </BDropdownForm>
        </BDropdown>
        <h5
          class="pb-0 pt-1 font-bold text-danger w-100 cursor-pointer"
          @click="resetFilter()"
        >
          Reset filter
        </h5>
      </BDropdown>
      <div class="d-flex rounded-lg align-items-center border search-bar ml-[10px] h-100">
        <span
          class="k-search-normal-1 h-100 font-bold text-10 align-middle mx-[5px]"
        />
        <BFormInput
          v-model="keyword"
          placeholder="Cari Nama"
          class="border-0"
          @input="handleSearch()"
        />
      </div>
      <div class="ml-[10px] w-[250px] ">
        <date-range-picker
          ref="picker"
          v-model="dateRange"
          :locale-data="locale"
          :ranges="ranges"
          :opens="'center'"
          @update="getListData()"
        >
          <!-- eslint-disable -->
          <template
            v-slot:input="dateRange"
            style="height: 100%"
          >
            <div class="d-flex justify-content-between align-items-center w-100">
              <div class="mr-1">
                <span
                  v-if="DDMMYYYY(dateRange.startDate) === DDMMYYYY(today)"
                  style="color: #828282 !important"
                >
                  Hari ini
                </span>
                <span
                  v-else-if="DDMMYYYY(dateRange.startDate) === DDMMYYYY(last2)"
                  style="color: #828282 !important"
                >
                  2 Hari Terakhir
                </span>
                <span
                  v-else-if="DDMMYYYY(dateRange.startDate) === DDMMYYYY(last7)"
                  style="color: #828282 !important"
                >
                  7 Hari Terakhir
                </span>
                <span
                  v-else-if="DDMMYYYY(dateRange.startDate) === DDMMYYYY(firstDateOfMonth) && DDMMYYYY(dateRange.endDate) === DDMMYYYY(today)"
                  style="color: #828282 !important"
                >
                  Bulan ini
                </span>
                <span
                  v-else
                  style="color: #828282 !important"
                >
                  {{`${DDMMYYYY(dateRange.startDate)} - ${DDMMYYYY(dateRange.endDate)}`}}
                </span>
              </div>
              <div>
                <span class="k-calendar-2" />
              </div>
            </div>
          </template>
        </date-range-picker>
      </div>
      <BButton
        tag="router-link"
        to="/training-program/create"
        variant="primary"
        class="ml-auto px-3"
      >
        <strong>Tambah</strong>
      </BButton>
    </BCol>

    <BOverlay
      id="scroll"
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <div
        id="table"
        style="height: calc(100vh - 275px); overflow-y: scroll;"
        class="mt-[24px]"
        @scroll="handleScroll"
      >
        <BTable
          :items="items"
          :fields="fields"
          empty-text="Tidak ada data yang ditampilkan."
          responsive
          show-empty
        >
          <template #cell(id)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(date)="data">
            {{ DATE_NUMBER_SLASH(data.item.start_date) }} - {{ DATE_NUMBER_SLASH(data.item.end_date) }}
          </template>
          <template #cell(type)="data">
            <span class="text-[#34A770] d-flex items-center gap-5" v-if="data.item.training_type === 'online'"><span class="w-[11px] h-[11px] d-flex rounded-full" style="height: 11px; width: 11px; background: #34A770;"/> Online</span>
            <span v-else>Offline</span>
          </template>
          <template #cell(location)="data">
            <span>{{ data.item.location === '' ? 'Zoom Meeting' : data.item.location }}</span>
          </template>
          <template #cell(level)="data">
            <div
              v-if="data.item.level === 'basic'"
              class="flex items-center basic"
            >
              Dasar
            </div>
            <div
              v-if="data.item.level === 'specific'"
              class="flex items-center specific"
            >
              Spesifik
            </div>
            <div
              v-if="data.item.level === 'mature'"
              class="flex items-center mature"
            >
              Pematangan
            </div>
          </template>
          <template #cell(action)="data">
            <div class="flex justify-end items-center">
              <BButton
                size="md"
                variant="outline-primary"
                :to="`training-program/${data.item.id}/detail`"
                tag="router-link"
              >
                Lihat
              </BButton>
            </div>
          </template>
        </BTable>
      </div>
    </BOverlay>

    <ModalDelete
      :id-item="idItem"
      @deleted="getListData()"
    />
  </BCard>
</template>

<script>
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { komtimAxiosIns } from '@/libs/axios'
import { DATE_NUMBER_SLASH, DDMMYYYY, YEAR_MONTH_DAY } from '@/libs/filterDate'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import {
  today,
  last2,
  last7,
  firstDateOfMonth,
} from '@/store/helpers'
import {
  tableTraining,
  levelOptions,
  eventTypeOptions,
  dateRange,
  locale,
  ranges,
} from './config'
import ModalDelete from './ModalDelete.vue'

export default {
  components: {
    ModalDelete,
    DateRangePicker,
  },
  data() {
    return {
      loading: false,
      items: [],
      limit: 20,
      offset: 0,
      lastData: false,
      keyword: '',
      fields: tableTraining,
      idItem: 0,
      filterSelected: [],
      listSkills: [],
      DATE_NUMBER_SLASH,
      DDMMYYYY,
      eventTypeOptions,
      levelOptions,
      eventTypeSelected: [],
      levelSelected: [],
      dateRange,
      locale,
      ranges,
      today,
      last2,
      last7,
      firstDateOfMonth,
    }
  },
  computed: {
    eventType() {
      return this.eventTypeSelected.join(',')
    },
    level() {
      return this.levelSelected.join(',')
    },
  },
  mounted() {
    this.$root.$on('bv::dropdown::show', bvEvent => {
      if (bvEvent.componentId === 'dropdown-2' || bvEvent.componentId === 'dropdown-3' || bvEvent.componentId === 'dropdown-4') {
        this.isDropdown2Visible = true
      }
    })
    this.$root.$on('bv::dropdown::hide', bvEvent => {
      if (bvEvent.componentId === 'dropdown-2' || bvEvent.componentId === 'dropdown-3' || bvEvent.componentId === 'dropdown-4') {
        this.isDropdown2Visible = false
      }
      if (this.isDropdown2Visible) {
        bvEvent.preventDefault()
      }
    })

    this.getListData()
  },
  methods: {
    handleScroll() {
      const table = document.getElementById('table')
      if (
        table.scrollTop >= table.scrollHeight - table.offsetHeight - 5
        && !this.loading
        && !this.lastData
      ) {
        this.getNextData()
      }
    },
    async getListData() {
      this.loading = true
      this.offset = 0
      const params = `offset=${this.offset}&limit=${this.limit}&keyword=${encodeURIComponent(this.keyword)}&level=${this.level}&training_type=${this.eventType}&start_date=${YEAR_MONTH_DAY(this.dateRange.startDate)}&end_date=${YEAR_MONTH_DAY(this.dateRange.endDate)}`
      const url = `v1/training_programs?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.items = data
          this.offset = data.length

          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async getNextData() {
      this.loading = true
      if (!this.lastData) {
        const params = `offset=${this.offset}&limit=${this.limit}&status=registered&skill=${this.skill}&keyword=${this.keyword}`
        const url = `v1/training_programs?${params}`

        await komtimAxiosIns
          .get(url)
          .then(res => {
            const { data } = res.data
            this.items.push(...data)
            this.offset += data.length
            this.loading = false
            if (data.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(err => {
            this.alertError(err)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    handleSearch: _.debounce(function search() {
      this.getListData()
    }, 1000),
    selectItem(id) {
      this.idItem = id
    },
    alertError(err) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title: 'Failure',
          icon: 'AlertCircleIcon',
          text: err.response.data.meta.message,
          variant: 'danger',
        },
      }, 1000)
    },
    resetFilter() {
      this.levelSelected = []
      this.eventTypeSelected = []
      this.getListData()
    },
  },
}
</script>

<style scoped lang="scss">
@import './style/Index.scss';
</style>
